import React, { useEffect, useState, useRef, useCallback } from "react"
import { List, Typography, Skeleton, Empty, Spin, Progress } from "antd"
import moment from "moment"
import axios from "axios"
import Avatar from "../../UI/Avatar/Avatar.component"
import * as _ from "lodash"
import { notification } from "antd"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"
const { Text, Paragraph } = Typography

const ContactItem = ({ item, userEmail }) => {
  const [details, setDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false)

  const fetchDetails = useCallback(async () => {
    if (hasAttemptedLoad) return

    setLoading(true)
    setHasAttemptedLoad(true)

    try {
      const response = await axios.request({
        url: "/v1/nylas/get_faded_contact_details",
        method: "GET",
        params: {
          email: userEmail,
          contact_email: item.contact.email,
        },
      })
      setDetails(response.data)
    } catch (error) {
      console.error("Failed to fetch contact details:", error)
    } finally {
      setLoading(false)
    }
  }, [userEmail, item.contact.email, hasAttemptedLoad, loading])

  useEffect(() => {
    fetchDetails()
  }, [])

  return (
    <List.Item
      key={item.contact.email}
      style={{
        padding: "16px",
        backgroundColor: "white",
        borderRadius: "8px",
        marginBottom: "12px",
        boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
        transition: "all 0.3s ease",
      }}
    >
      <List.Item.Meta
        avatar={
          <Avatar
            avatar={null}
            size={40}
            firstName={details?.contact?.full_name?.split(" ")[0]}
            lastName={details?.contact?.full_name?.split(" ")[1]}
            email={details?.contact?.email}
            colorNumber={details?.last_message_at || item.last_message_at}
          />
        }
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div>
              <Text strong style={{ fontSize: "15px" }}>
                {details?.contact?.full_name || item.contact.email}
              </Text>
              <br />
              <Text type="secondary" style={{ fontSize: "13px" }}>
                {item.contact.email}
              </Text>
            </div>
            <Text
              type="secondary"
              style={{ fontSize: "13px", whiteSpace: "nowrap" }}
            >
              {moment
                .unix(details?.last_message?.timestamp || item.last_message_at)
                .format("D MMM YYYY")}
            </Text>
          </div>
        }
      />
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <div style={{ marginLeft: "52px", marginTop: "8px" }}>
          <Paragraph
            ellipsis={{ rows: 2 }}
            style={{
              margin: 0,
              fontSize: "13px",
              color: "#6c757d",
              lineHeight: "1.5",
            }}
          >
            {details?.last_message?.subject && (
              <Text strong style={{ display: "block", marginBottom: "4px" }}>
                {details.last_message.subject}
              </Text>
            )}
            {_.truncate(details?.last_message?.body, { length: 100 })}
          </Paragraph>
        </div>
      )}
    </List.Item>
  )
}

const LoadingSkeleton = () => (
  <div style={{ padding: "16px" }}>
    <div
      style={{
        padding: "16px",
        backgroundColor: "white",
        borderRadius: "8px",
        marginBottom: "12px",
        boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
      }}
    >
      <div style={{ display: "flex", alignItems: "flex-start", gap: "12px" }}>
        <Skeleton.Avatar active size={44} />
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "8px",
            }}
          >
            <Skeleton.Input style={{ width: "200px" }} active size="small" />
            <Skeleton.Input style={{ width: "100px" }} active size="small" />
          </div>
          <Skeleton.Input style={{ width: "100%" }} active size="small" />
        </div>
      </div>
    </div>
  </div>
)

const FadedConvos = ({ contacts, userEmail }) => {
  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)
  const [participants, setParticipants] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      // Poll for status
      const interval = setInterval(async () => {
        const status = await axios.get("/v1/nylas/get_recent_participants_status", {
          params: { email: userEmail },
        })

        setProgress(status.data.progress)

        if (status.data.status === "error") {
          clearInterval(interval)
          notification.error({
            message: "Error",
            description: status.data.error || "Failed to process participants",
          })
          setLoading(false)
        } else if (status.data.status === "participants_collected") {
          const result = await axios.get("/v1/nylas/get_faded_conversations", {
            params: { email: userEmail },
          })
          setParticipants(result.data.faded_contacts)
          setProgress(100)
        } else if (status.data.status === "completed") {
          clearInterval(interval)
          setLoading(false)
        }
      }, 2000)

      // Add timeout after 2 minutes
      setTimeout(() => {
        clearInterval(interval)
        notification.warning({
          message: "Timeout",
          description: "Request took too long. Please try again.",
        })
        setLoading(false)
      }, 120000)

      return () => clearInterval(interval)
    }

    fetchData()
  }, [userEmail])

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          padding: "20px",
          width: "80%",
          margin: "auto",
        }}
      >
        <Progress
          type="line"
          strokeColor="#F4C041FF"
          percent={progress}
          status={progress < 100 ? "active" : "success"}
          style={{ marginTop: "20px" }}
        />
        <RelatableLoader
          quote={true}
          customQuote={
            progress < 50
              ? "Collecting recent participants..."
              : "Processing older conversations..."
          }
          loading={true}
        />
      </div>
    )
  }

  if (!participants.length) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Empty
          description="No dormant contacts found"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      </div>
    )
  }

  return (
    <div style={{ padding: "20px 24px" }}>
      <div style={{ marginBottom: "20px" }}>
        <h3 style={{ margin: 0, fontSize: "18px", fontWeight: 500 }}>
          Dormant Contacts ({participants.length})
        </h3>
        <Text type="secondary" style={{ fontSize: "14px" }}>
          Contacts you haven't communicated with recently
        </Text>
      </div>
      <List
        itemLayout="vertical"
        dataSource={_.orderBy(participants, "last_message_at", "desc")}
        renderItem={(item) => (
          <ContactItem key={item.contact.email} item={item} userEmail={userEmail} />
        )}
      />
    </div>
  )
}

export default React.memo(FadedConvos)
