import { useSelector } from "react-redux"
import { useCallback } from "react"

/**
 * Hook for handling the ordering of spheres in the application
 *
 * This hook provides functionality to:
 * 1. Add order properties to sphere objects based on a stored order preference
 * 2. Sort spheres based on their order
 */
export const useSpheresOrder = () => {
  // Get the saved sphere order from Redux state
  const spheres_order = useSelector((state) => state.CollectionsState.spheres_order)

  /**
   * Adds an 'order' property to each sphere object
   * @param {Array} spheresToOrder - Main spheres array to be ordered
   * @param {Array} teamSpheres - Optional array of team spheres to include
   * @returns {Array} Spheres with order properties added
   */
  const addOrderToSpheres = useCallback(
    (spheresToOrder, teamSpheres = []) => {
      if (!spheresToOrder) return []

      // Combine regular spheres with team spheres if provided
      const allSpheres = teamSpheres
        ? [...spheresToOrder, ...teamSpheres]
        : spheresToOrder

      if (spheres_order) {
        // If we have a saved order preference, use it to assign order values
        return allSpheres.map((item) => {
          const copy = { ...item }
          // Find sphere's position in saved order array
          copy.order = spheres_order.findIndex((o) => o === item.id)
          // If sphere isn't in saved order, put it at the end
          if (copy.order === -1) {
            copy.order = spheres_order.length + item.id
          }
          return copy
        })
      } else {
        // If no saved order, use array index as order
        return allSpheres.map((item, index) => {
          const copy = { ...item }
          copy.order = index
          return copy
        })
      }
    },
    [spheres_order]
  )

  /**
   * Orders spheres based on their order property
   * @param {Array} spheresToOrder - Main spheres to order
   * @param {Array} teamSpheres - Optional team spheres to include in ordering
   * @returns {Array} Sorted array of spheres
   */
  const orderSpheres = useCallback(
    (spheresToOrder, teamSpheres = []) => {
      return addOrderToSpheres(spheresToOrder, teamSpheres).sort(
        (a, b) => a.order - b.order
      )
    },
    [addOrderToSpheres]
  )

  return { orderSpheres, addOrderToSpheres }
}
