import React, { useEffect, useRef, useState } from "react"
import * as _ from "lodash"
import "./CollectionSelector.styles.scss"
import { Select, Spin } from "antd"
import { useSelector } from "react-redux"
import ConfirmPopup from "./ConfirmPopup.component"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"
import { useSpheresOrder } from "../../../hooks/useSpheresOrder"
import Icon from "@ant-design/icons"
import { ReactComponent as UserGroupIcon } from "../../Icons/UserGroup.svg"

const { Option } = Select

const CollectionSelector = ({
  submitMode,
  collectionType,
  collectionTitle,
  currentElements = [],
  handleSubmit,
  disabled = false,
}) => {
  const select = useRef(null)
  const { orderSpheres } = useSpheresOrder()
  const spheres_order = useSelector((state) => state.CollectionsState.spheres_order)

  const spheres = useSelector((state) => state.CollectionsState.spheres)
  const teamSharedSpheres = useSelector(
    (state) => state.CollectionsState.team_shared_spheres
  )
  const topics = useSelector((state) => state.CollectionsState.topics)
  const companies = useSelector((state) => state.CollectionsState.companies)
  const expertises = useSelector((state) => state.CollectionsState.expertises)

  const [openConfirmPopup, setOpenConfirmPopup] = useState(false)
  const [collection, setCollection] = useState(null)
  const [options, setOptions] = useState(null)
  const [onBlurDisable, setOnBlurDisable] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [existingElements, setExistingElements] = useState([])

  const [selected, setSelected] = useState([])
  const [newElements, setNewElements] = useState([])
  const [foreignElements, setForeignElements] = useState([]) // For elements from other databases that aren't shared

  useEffect(() => {
    switch (collectionType) {
      case "spheres":
        setCollection(spheres)
        break
      case "topics":
        setCollection(topics)
        break
      case "companies":
        setCollection(companies)
        break
      case "expertises":
        setCollection(expertises)
        break
      default:
        break
    }
  }, [collectionType, spheres, topics, companies, expertises])

  useEffect(() => {
    if (currentElements) {
      // Filter and map currentElements
      const selected = currentElements.map((element) =>
        element.id ? element.id : element.title
      )

      const foreignElements = currentElements.filter(
        (element) => !spheres.map((s) => s.id).includes(element.id)
      )
      setForeignElements(foreignElements)

      setSelected(selected)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (collection) {
      populateOptions()
    }
    // eslint-disable-next-line
  }, [collection, spheres_order])

  useEffect(() => {
    if (collection) {
      let filteredElements = collection.filter((element) =>
        selected.includes(element.id)
      )

      if (collectionType === "spheres") {
        const selectedTeamSpheres = teamSharedSpheres.filter((sphere) =>
          selected.includes(sphere.id)
        )
        filteredElements = orderSpheres(filteredElements, selectedTeamSpheres)
      }

      filteredElements = [...filteredElements, ...foreignElements]

      setExistingElements(filteredElements)
    }
    // eslint-disable-next-line
  }, [selected, spheres_order])

  useEffect(() => {
    setNewElements(
      selected.filter((element) => {
        if (typeof element === "string") {
          return true
        }
        return !existingElements.map((e) => e.id).includes(element)
      })
    )
    // eslint-disable-next-line
  }, [existingElements])

  useEffect(() => {
    if (submitMode !== "onClick") {
      handleSubmit(existingElements, newElements)
    }
    // eslint-disable-next-line
  }, [newElements])

  const populateOptions = () => {
    let optionsToShow = collection

    if (collectionType === "spheres") {
      optionsToShow = orderSpheres(collection, teamSharedSpheres)
    }

    // Include foreign elements in the options
    optionsToShow = [...optionsToShow, ...foreignElements]

    setOptions(
      optionsToShow.map((element) => (
        <Option key={element.id} value={element.id}>
          {element.team_shared && (
            <Icon style={{ marginRight: "8px" }} component={UserGroupIcon} />
          )}
          {element.title}
        </Option>
      ))
    )
  }

  return !collection ? (
    <RelatableLoader />
  ) : (
    <div>
      {submitMode === "onClick" && (
        <ConfirmPopup
          open={openConfirmPopup}
          goBack={() => setOpenConfirmPopup(false)}
          cancelChanges={() => {
            setOpenConfirmPopup(false)
            setDropdownOpen(false)
            setSelected([])
          }}
        />
      )}
      <Select
        open={dropdownOpen}
        onDropdownVisibleChange={(open) => {
          if (!onBlurDisable) {
            if (submitMode === "onClick") {
              if (selected.length > 0) {
                setOpenConfirmPopup(true)
              } else {
                setDropdownOpen(open)
              }
            } else {
              setDropdownOpen(open)
            }
          }
        }}
        showSearch
        filterOption={(input, option) =>
          option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        optionFilterProp="children"
        disabled={disabled}
        bordered={false}
        mode={"tags"}
        ref={select}
        allowClear={true}
        value={selected}
        className="CollectionSelector"
        listHeight={200}
        placeholder={collectionTitle || _.capitalize(collectionType.toString())}
        onChange={(collection) => {
          setSelected(collection)
        }}
        dropdownClassName="CollectionSelector_Dropdown"
        getPopupContainer={(trigger) => trigger.parentNode}
        dropdownRender={(menu) => (
          <div>
            <div>{menu}</div>
            {submitMode === "onClick" && (
              <div
                className={"CollectionSelector_Dropdown_SubmitButton"}
                onMouseEnter={() => setOnBlurDisable(true)}
                onMouseLeave={() => setOnBlurDisable(false)}
                onClick={() => {
                  handleSubmit(existingElements, newElements)
                  setSelected([])
                  setOnBlurDisable(false)
                  setDropdownOpen(false)
                }}
              >
                Done
              </div>
            )}
          </div>
        )}
        labelInValue={false}
        optionLabelProp="children"
      >
        {options}
      </Select>
    </div>
  )
}

export default CollectionSelector
