import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./RelatableLoader.scss"
import { RiseLoader } from "react-spinners"
import { useSelector } from "react-redux"

const Quotes = [
  "Reticulating splines…",
  "Googling for your contacts…",
  "Stay on target…. Stay on target….",
  "Red leader standing by…",
  "Hang on a sec, I know the data is here somewhere…",
  "Counting backwards from infinity…",
  "Making magic happen…",
  "Bending the spoon",
  "Would you like guacamole with that",
  "Determining if a hot dog is a sandwich, hold tight",
  "I'm sorry Dave, I can't do that",
  "Adjusting flux capacitor",
  "Almost done, I promise",
  "Let's just enjoy the journey, shall we",
  "Replacing all the 1s with 0",
  "Sorry I'm not home right now I'm walking in a spiderweb",
  "Spinning the hamster as fast as it can go",
  "If you type Google into Google you can break the internet",
  "In Soviet Russia, contacts load you.",
  "Just putting on some finishing touches…",
  "Staying focused I promi - oh, squirrel!",
]

const sizes = {
  small: 10,
  medium: 20,
  large: 30,
}
const RelatableLoader = ({
  children,
  size = "small",
  loading = true,
  color = "#F4C041FF",
  quote = false,
  customQuote = null,
}) => {
  const repetitiveAction = useSelector((state) => state.AppState.repetitiveAction)
  const [currentQuote, setCurrentQuote] = useState(null)

  useEffect(() => {
    // Don't set up interval if using custom quote
    if (customQuote) {
      setCurrentQuote(customQuote)
      return
    }

    // Set initial quote
    setCurrentQuote(Quotes[Math.floor(Math.random() * Quotes.length)])

    // Update quote every 10 seconds
    const interval = setInterval(() => {
      setCurrentQuote(Quotes[Math.floor(Math.random() * Quotes.length)])
    }, 10000)

    // Cleanup interval on unmount
    return () => clearInterval(interval)
  }, [customQuote]) // Re-run effect if customQuote changes

  return (
    <div className={"RelatableLoader"}>
      {loading && !repetitiveAction ? (
        <div
          className={`${
            !!children && loading ? "RelatableLoader_Mask" : "RelatableLoader_Flex"
          }`}
        >
          <span className={`${!!children && loading ? "RelatableLoader_Icon" : ""}`}>
            <RiseLoader color={color} loading={loading} size={sizes[size]} />
          </span>
          {/*<span className={"RelatableLoader_IconSecondary"}>*/}
          {/*  <RiseLoader color={"#F2C166FF"} loading={loading} size={sizes[size]} />*/}
          {/*</span>*/}
          {quote && (
            <span
              role={"note"}
              className={`${!!children && loading ? "RelatableLoader_Quote" : ""}`}
            >
              {currentQuote}
            </span>
          )}
        </div>
      ) : null}
      {children}
    </div>
  )
}

RelatableLoader.propTypes = {
  size: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
}

export default RelatableLoader
