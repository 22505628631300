export const FETCH_PEOPLE = "FETCH_PEOPLE"
export const FETCH_PEOPLE_SUCCESS = "FETCH_PEOPLE_SUCCESS"
export const FETCH_PEOPLE_FAIL = "FETCH_PEOPLE_FAIL"

export const FETCH_PEOPLE_FROM_ALL_PAGES = "FETCH_PEOPLE_FROM_ALL_PAGES"
export const FETCH_PEOPLE_FROM_ALL_PAGES_SUCCESS =
  "FETCH_PEOPLE_FROM_ALL_PAGES_SUCCESS"
export const FETCH_PEOPLE_FROM_ALL_PAGES_FAIL = "FETCH_PEOPLE_FROM_ALL_PAGES_FAIL"
export const ADD_NOTIFY_ABOUT_10000_RECORDS = "ADD_NOTIFY_ABOUT_10000_RECORDS"

export const FETCH_PERSON = "FETCH_PERSON"
export const FETCH_PERSON_SUCCESS = "FETCH_PERSON_SUCCESS"
export const FETCH_PERSON_FAIL = "FETCH_PERSON_FAIL"

export const FETCH_PERSONS_INTERACTIONS = "FETCH_PERSONS_INTERACTIONS"
export const FETCH_PERSONS_INTERACTIONS_SUCCESS =
  "FETCH_PERSONS_INTERACTIONS_SUCCESS"

export const FETCH_PERSONS_EVENTS = "FETCH_PERSONS_EVENTS"
export const FETCH_PERSONS_EVENTS_SUCCESS = "FETCH_PERSONS_EVENTS_SUCCESS"

export const FETCH_PERSONS_NOTES = "FETCH_PERSONS_NOTES"
export const FETCH_PERSONS_NOTES_SUCCESS = "FETCH_PERSONS_NOTES_SUCCESS"

export const FETCH_PERSONS_REMINDERS = "FETCH_PERSONS_REMINDERS"
export const FETCH_PERSONS_REMINDERS_SUCCESS = "FETCH_PERSONS_REMINDERS_SUCCESS"

export const CREATE_CONTACT = "CREATE_CONTACT"
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS"
export const CREATE_CONTACT_FAIL = "CREATE_CONTACT_FAIL"

export const EDIT_CONTACT = "EDIT_CONTACT"
export const EDIT_CONTACT_SUCCESS = "EDIT_CONTACT_SUCCESS"
export const EDIT_CONTACT_FAIL = "EDIT_CONTACT_FAIL"

export const UPDATE_PERSON_SPHERES = "UPDATE_PERSON_SPHERES"
export const UPDATE_PERSON_SPHERES_SUCCESS = "UPDATE_PERSON_SPHERES_SUCCESS"
export const UPDATE_PERSON_SPHERES_FAIL = "UPDATE_PERSON_SPHERES_FAIL"

export const DELETE_CONTACT = "DELETE_CONTACT"
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS"
export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL"

export const UPDATE_CONTACT_IMAGE = "UPDATE_CONTACT_IMAGE"
export const UPDATE_CONTACT_IMAGE_SUCCESS = "UPDATE_CONTACT_IMAGE_SUCCESS"
export const UPDATE_CONTACT_IMAGE_FAIL = "UPDATE_CONTACT_IMAGE_FAIL"

export const DELETE_CONTACTS = "DELETE_CONTACTS"
export const DELETE_CONTACTS_SUCCESS = "DELETE_CONTACTS_SUCCESS"
export const DELETE_CONTACTS_FAIL = "DELETE_CONTACTS_FAIL"

export const EDIT_CONTACTS = "EDIT_CONTACTS"
export const EDIT_CONTACTS_SUCCESS = "EDIT_CONTACTS_SUCCESS"
export const EDIT_CONTACTS_FAIL = "EDIT_CONTACTS_FAIL"

export const MERGE_CONTACTS = "MERGE_CONTACTS"
export const MERGE_CONTACTS_SUCCESS = "MERGE_CONTACTS_SUCCESS"
export const MERGE_CONTACTS_FAIL = "MERGE_CONTACTS_FAIL"

export const EXPORT_CONTACTS = "EXPORT_CONTACTS"
export const EXPORT_CONTACTS_SUCCESS = "EXPORT_CONTACTS_SUCCESS"
export const EXPORT_CONTACTS_FAIL = "EXPORT_CONTACTS_FAIL"

export const CLEAR_REDUX_PEOPLE_STATE = "CLEAR_REDUX_PEOPLE_STATE"
export const SET_INITIAL_REDUX_PEOPLE_STATE = "SET_INITIAL_REDUX_PEOPLE_STATE"

export const UNARCHIVE_CONTACT = "UNARCHIVE_CONTACT"
export const UNARCHIVE_CONTACT_SUCCESS = "UNARCHIVE_CONTACT_SUCCESS"
export const UNARCHIVE_CONTACT_FAIL = "UNARCHIVE_CONTACT_FAIL"

export const FETCH_SELECTED_PEOPLE = "FETCH_SELECTED_PEOPLE"
export const FETCH_SELECTED_PEOPLE_SUCCESS = "FETCH_SELECTED_PEOPLE_SUCCESS"
export const FETCH_SELECTED_PEOPLE_FAIL = "FETCH_SELECTED_PEOPLE_FAIL"

export const FETCH_DUPLICATES_COUNT = "FETCH_DUPLICATES_COUNT"
export const FETCH_DUPLICATES = "FETCH_DUPLICATES"
export const FETCH_DUPLICATES_SUCCESS = "FETCH_DUPLICATES_SUCCESS"
export const FETCH_DUPLICATES_FAIL = "FETCH_DUPLICATES_FAIL"

export const APPROVE_DUPLICATE = "APPROVE_DUPLICATES"
export const APPROVE_DUPLICATE_SUCCESS = "APPROVE_DUPLICATES_SUCCESS"
export const APPROVE_DUPLICATE_FAIL = "APPROVE_DUPLICATES_FAIL"

export const IGNORE_DUPLICATE = "IGNORE_DUPLICATE"
export const IGNORE_DUPLICATE_SUCCESS = "IGNORE_DUPLICATE_SUCCESS"
export const IGNORE_DUPLICATE_FAIL = "IGNORE_DUPLICATE_FAIL"

export const FETCH_ENRICHMENT_COUNT = "FETCH_ENRICHMENT_COUNT"
export const FETCH_ENRICHMENT_COUNT_SUCCESS = "FETCH_ENRICHMENT_COUNT_SUCCESS"
export const FETCH_ENRICHMENT_COUNT_FAIL = "FETCH_ENRICHMENT_COUNT_FAIL"
export const FETCH_ENRICHMENTS = "FETCH_ENRICHMENTS"
export const FETCH_ENRICHMENTS_SUCCESS = "FETCH_ENRICHMENTS_SUCCESS"
export const FETCH_ENRICHMENTS_FAIL = "FETCH_ENRICHMENTS_FAIL"

export const APPROVE_ENRICHMENT = "APPROVE_ENRICHMENT"
export const APPROVE_ENRICHMENT_SUCCESS = "APPROVE_ENRICHMENT_SUCCESS"
export const APPROVE_ENRICHMENT_FAIL = "APPROVE_ENRICHMENT_FAIL"

export const IGNORE_ENRICHMENT = "IGNORE_ENRICHMENT"
export const IGNORE_ENRICHMENT_SUCCESS = "IGNORE_ENRICHMENT_SUCCESS"
export const IGNORE_ENRICHMENT_FAIL = "IGNORE_ENRICHMENT_FAIL"

export const SHOW_PEOPLE_BY_IDS = "SHOW_PEOPLE_BY_IDS"
export const SHOW_PEOPLE_BY_IDS_SUCCESS = "SHOW_PEOPLE_BY_IDS_SUCCESS"
export const SHOW_PEOPLE_BY_IDS_FAIL = "SHOW_PEOPLE_BY_IDS_FAIL"

export const UPDATE_ENABLED_COLUMNS = "UPDATE_ENABLED_COLUMNS"
export const UPDATE_ENABLED_COLUMNS_SUCCESS = "UPDATE_ENABLED_COLUMNS_SUCCESS"
export const UPDATE_ENABLED_COLUMNS_FAIL = "UPDATE_ENABLED_COLUMNS_FAIL"

export const SET_FETCHING_STATE = "SET_FETCHING_STATE"

export const CLONE_TEAM_SHARED_PERSON_TO_MY_DB = "CLONE_TEAM_SHARED_PERSON_TO_MY_DB"
export const CLONE_TEAM_SHARED_PERSON_TO_MY_DB_SUCCESS =
  "CLONE_TEAM_SHARED_PERSON_TO_MY_DB_SUCCESS"
export const CLONE_TEAM_SHARED_PERSON_TO_MY_DB_FAIL =
  "CLONE_TEAM_SHARED_PERSON_TO_MY_DB_FAIL"
