import React, { useEffect, useState } from "react"
import moment from "moment"
import StrengthIndicator from "../StrengthIndicator/StrengthIndicator.component"
import ImportanceIndicator from "../ImportanceIndicator/ImportanceIndicator.component"
import { Checkbox, Dropdown, Table, Tooltip } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { FlagOutlined } from "@ant-design/icons/lib"
import {
  deleteContact,
  fetchPeople,
  fetchPeopleFromAllPages,
  unarchiveContact,
  updateEnabledColumns,
  updatePersonSpheres,
} from "../../../redux/People/People.actions"
import {
  ArrayParam,
  BooleanParam,
  StringParam,
  useQueryParam,
} from "use-query-params"
import ImportanceSelector from "../ImportanceSelector/ImportanceSelector.component"
import StrengthSelector from "../StrengthSelector/StrengthSelector.component"
import RecencySelector from "../RecencySelector/RecencySelector.component"
import "./PeopleTable.styles.scss"
import SpheresSelector from "../SpheresSelector/SpheresSelector.component"
import EmptyState from "../../UI/EmptyState/EmptyState.ui"
import {
  deselectContact,
  deselectContacts,
  selectContact,
  selectContacts,
  setActiveTab,
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../../redux/App/App.actions"
import Avatar from "../../UI/Avatar/Avatar.component"
import ArrowDownIcon from "../../Icons/ArrowDown.icons"
import ArrowUpIcon from "../../Icons/ArrowUp.icons"
import Icon from "@ant-design/icons"
import CustomPagination from "../CustomPagination/CustomPagination.component"
import OverflowTip from "../../UI/OverflowTip/OverflowTip.ui"
import TableColumnsIcons from "../../Icons/TableColumns.icons"
import { formatPhoneNumber } from "../../../helpers/helpers"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"
import { ReactComponent as UserIcon } from "../../Icons/UserIcon.svg"
import * as _ from "lodash"
import SharedContactDrawer from "../../SharedCollection/SharedContactDrawer.component"

const PeopleTable = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.UserState)
  const fetching = useSelector((state) => state.PeopleState.fetching)
  const selectedContactsIds = useSelector(
    (state) => state.AppState.selected_contacts_ids
  )
  const enabledColumns = useSelector((state) => state.PeopleState.enabled_columns)

  const queriedPeople = useSelector((state) => state.PeopleState.queriedPeople)
  const [dataProvided] = useQueryParam("data_provided", BooleanParam)

  const [page, setPage] = useQueryParam("people_page", StringParam)
  const [sortField, setSortField] = useQueryParam("people_by", StringParam)
  const [sortOrder, setSortOrder] = useQueryParam("people_order", StringParam)
  const [searchValue] = useQueryParam("q", StringParam)
  const [filters] = useQueryParam("people_filters", ArrayParam)

  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )
  const visibleContactID = useSelector((state) => state.AppState.visibleContactID)

  const cloningContact = useSelector((state) => state.PeopleState.cloningContact)

  const [settingLastSent, setSettingLastSent] = useState(false)
  const [settingImportance, setSettingImportance] = useState(false)
  const [settingStrength, setSettingStrength] = useState(false)
  const [settingSpheres, setSettingSpheres] = useState(false)
  const [hoveringFlag, setHoveringFlag] = useState(false)
  const [selectedAllOnPage, setSelectedAllOnPage] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedSharedContact, setSelectedSharedContact] = useState(null)

  const onHandleUpdateEnabledColumn = (checkedValues) => {
    dispatch(updateEnabledColumns(checkedValues, enabledColumns))
  }

  const options = [
    { label: "Engagement", value: "engagement" },
    { label: "Name", value: "full_name", disabled: true },
    { label: "Strength", value: "strength" },
    { label: "Last Sent", value: "last_sent" },
    { label: "Last Received", value: "last_received" },
    { label: "Importance", value: "importance" },
    { label: "Birthday", value: "birthday" },
    { label: "Has Active Task", value: "have_active_task" },
    { label: "Topics", value: "topics" },
    { label: "Companies", value: "companies" },
    { label: "Expertises", value: "expertises" },
    { label: "Email Addresses", value: "email_addresses" },
    { label: "Phone Numbers", value: "phone_numbers" },
    { label: "Addresses", value: "addresses" },
  ]

  const customizeColumnMenu = (
    <div>
      <Checkbox.Group
        className="CustomizeColumnMenu"
        options={options}
        value={enabledColumns}
        defaultValue={["name"]}
        onChange={onHandleUpdateEnabledColumn}
        onMouseLeave={() => setDropdownOpen(false)}
      />
    </div>
  )

  const getSortOrder = (field) => {
    if (sortOrder && field === sortField) {
      return sortOrder === "asc" ? "ascend" : "descend"
    } else {
      return null
    }
  }

  const lastCanoncialInteractions = (record, key = "last_sent") => {
    let lastInteractions = []
    if (record.canonical_participants == null) {
      return null
    }
    Object.keys(record.canonical_participants).length > 0 &&
      Object.keys(record.canonical_participants).forEach((k) => {
        lastInteractions = [...lastInteractions, ...record.canonical_participants[k]]
      })

    if (lastInteractions.length === 0) {
      return null
    }

    lastInteractions.sort((a, b) => {
      //   sort by key
      return new Date(b[key]) - new Date(a[key])
    })

    if (!lastInteractions[0][key]) {
      return null
    }

    lastInteractions = _.uniqBy(lastInteractions, "user_id")

    return (
      <Tooltip
        title={lastInteractions
          .slice(0, 2)
          .map(
            (p) =>
              p.user_name +
              ": " +
              (p[key]
                ? moment(p[key]).tz(user.time_zone).format("MMM Do, YYYY") + "\n"
                : "N/A \n")
          )}
      >
        <div className="PeopleTable_CanonicalInteractions">
          <div className="PeopleTable_CanonicalInteractions_Icon">
            <Icon component={UserIcon} />
          </div>
          <span>
            {moment(lastInteractions[0][key])
              .tz(user.time_zone)
              .format("MMM Do, YYYY")}
          </span>
        </div>
      </Tooltip>
    )
  }

  const renderSorterIcon = (sortColumns, columnKey) => {
    const sortedColumn = sortColumns?.find(({ column }) => column.key === columnKey)

    if (sortedColumn?.order === "ascend") {
      return <Icon className="PeopleTable_SorterIcon" component={ArrowUpIcon} />
    } else if (sortedColumn?.order === "descend") {
      return <Icon className="PeopleTable_SorterIcon" component={ArrowDownIcon} />
    } else {
      return null
    }
  }

  const columns = [
    {
      className: "engagement hoverable",
      key: "engagement",
      width: 6,
      fixed: "left",
      render: (record) => (
        <div
          className={`PeopleTable_Engagement PeopleTable_Engagement-${record.engagement_status}`}
        ></div>
      ),
    },
    {
      title: ({ sortColumns }) => {
        return (
          <div
            className={
              totalEntries === 0 ? "PeopleTable_NameAndAvatar--paddingLeft" : ""
            }
          >
            Name
            {renderSorterIcon(sortColumns, "full_name")}
          </div>
        )
      },
      className: "name hoverable",
      key: "full_name",
      fixed: "left",
      RC_TABLE_INTERNAL_COL_DEFINE: { style: { minWidth: "250px" } }, // << this is the magic which set min width for column
      ellipsis: true,
      sorter: queriedPeople?.length > 0,
      defaultSortOrder: getSortOrder("full_name"),
      render: (record) => (
        <div className="PeopleTable_NameAndAvatar">
          <Avatar
            avatar={record?.profile_image_url}
            firstName={record?.full_name?.split(" ")[0]}
            lastName={record?.full_name?.split(" ")[1]}
            colorNumber={record.id}
          />
          <div className="PeopleTable_NameAndAvatar_Name">
            <span className="PeopleTable_NameAndAvatar_Name_FullName">
              {(record?.full_name?.trim()?.length > 0 && record.full_name) || ""}
            </span>
            {(record.one_liner || record.companies?.find((t) => t.title)) && (
              <OverflowTip
                placement="right"
                titleClass="PeopleTable_NameAndAvatar_Name_OneLiner"
              >
                {record.one_liner ||
                  record.companies?.map((t) => t.title).join(", ")}
              </OverflowTip>
            )}
          </div>
        </div>
      ),
    },
    {
      title: ({ sortColumns }) => {
        return (
          <div>
            Strength
            {renderSorterIcon(sortColumns, "strength")}
          </div>
        )
      },
      className: "strength hoverable",
      key: "strength",
      width: 90,
      sorter: queriedPeople?.length > 0,
      defaultSortOrder: getSortOrder("strength"),
      onCell: (record) => {
        return {
          onClick: (e) => {
            setSettingLastSent(false)
            setSettingImportance(false)
            setSettingStrength(true)
            if (contactDrawerVisible && visibleContactID === record.id) {
              setSettingStrength(!settingStrength)
              setSettingLastSent(false)
              setSettingImportance(false)
              e.stopPropagation()
            }
          },
        }
      },
      render: (record) => (
        <StrengthSelector
          opened={visibleContactID === record.id && settingStrength}
          id={record.id}
        >
          <StrengthIndicator level={record.strength} />
        </StrengthSelector>
      ),
    },
    {
      title: ({ sortColumns }) => {
        return (
          <div>
            Last Sent
            {renderSorterIcon(sortColumns, "last_sent")}
          </div>
        )
      },
      className: "last_sent hoverable",
      key: "last_sent",
      width: 130,
      sorter: queriedPeople?.length > 0,
      defaultSortOrder: getSortOrder("last_sent"),
      onCell: (record) => {
        return {
          onClick: (e) => {
            setSettingLastSent(true)
            setSettingImportance(false)
            setSettingStrength(false)
            if (contactDrawerVisible && visibleContactID === record.id) {
              setSettingLastSent(!settingLastSent)
              setSettingImportance(false)
              setSettingStrength(false)
              e.stopPropagation()
            }
          },
        }
      },
      render: (record) => (
        <>
          <RecencySelector
            opened={visibleContactID === record.id && settingLastSent}
            id={record.id}
            onClose={() => setSettingLastSent(false)}
            onSubmit={() => setSettingLastSent(false)}
          >
            <div className="PeopleTable_LastSent">
              {record.last_sent ? (
                <span>
                  {moment(record.last_sent)
                    .tz(user.time_zone)
                    .format("MMM Do, YYYY")}
                </span>
              ) : (
                <span>N/A</span>
              )}
            </div>
          </RecencySelector>
          {lastCanoncialInteractions(record)}
        </>
      ),
    },
    {
      title: ({ sortColumns }) => {
        return (
          <div>
            Last Received
            {renderSorterIcon(sortColumns, "last_received")}
          </div>
        )
      },
      className: "last_received hoverable",
      key: "last_received",
      width: 130,
      sorter: queriedPeople?.length > 0,
      defaultSortOrder: getSortOrder("last_received"),
      onCell: (record) => {
        return {
          onClick: (e) => {
            setSettingLastSent(true)
            setSettingImportance(false)
            setSettingStrength(false)
            if (contactDrawerVisible && visibleContactID === record.id) {
              setSettingLastSent(!settingLastSent)
              setSettingImportance(false)
              setSettingStrength(false)
              e.stopPropagation()
            }
          },
        }
      },
      render: (record) => (
        <>
          <div className="PeopleTable_LastSent">
            {record.last_received ? (
              <span>
                {moment(record.last_received)
                  .tz(user.time_zone)
                  .format("MMM Do, YYYY")}
              </span>
            ) : (
              <span>N/A</span>
            )}
          </div>
          {lastCanoncialInteractions(record, "last_received")}
        </>
      ),
    },
    {
      title: ({ sortColumns }) => {
        return (
          <div>
            Importance
            {renderSorterIcon(sortColumns, "importance")}
          </div>
        )
      },
      className: "importance hoverable",
      key: "importance",
      width: 125,
      sorter: queriedPeople?.length > 0,
      defaultSortOrder: getSortOrder("importance"),
      onCell: (record) => {
        return {
          onClick: (e) => {
            setSettingLastSent(false)
            setSettingImportance(true)
            setSettingStrength(false)
            if (contactDrawerVisible && visibleContactID === record.id) {
              setSettingImportance(!settingImportance)
              e.stopPropagation()
            }
          },
        }
      },
      render: (record) => (
        <ImportanceSelector
          opened={visibleContactID === record.id && settingImportance}
          id={record.id}
        >
          <ImportanceIndicator level={record.importance} />
        </ImportanceSelector>
      ),
    },
    {
      title: "Birthday",
      className: "birthday hoverable",
      key: "birthday",
      width: 80,
      render: (record) => (
        <div className="PeopleTable_LastSent">
          <span>
            {record.birthday
              ? moment(record.birthday).tz(user.time_zone).format("MMM Do")
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "Task",
      className: "have_active_task hoverable",
      key: "have_active_task",
      width: 50,
      onCell: () => {
        return {
          onClick: () => {
            setSettingLastSent(false)
            setSettingImportance(false)
            setSettingStrength(false)
          },
        }
      },
      render: (record) => (
        <div className="PeopleTable_LastSent">
          <span>{record.have_active_task ? "Yes" : "No"}</span>
        </div>
      ),
    },
    {
      title: "Topics",
      className: "topics hoverable",
      key: "topics",
      width: 150,
      onCell: () => {
        return {
          onClick: () => {
            setSettingLastSent(false)
            setSettingImportance(false)
            setSettingStrength(false)
          },
        }
      },
      render: (record) => (
        <div className="PeopleTable_Topics">
          <span>{record.topics?.map((t) => t.title).join(", ")}</span>
        </div>
      ),
    },
    {
      title: "Companies",
      className: "companies hoverable",
      key: "companies",
      width: 150,
      onCell: () => {
        return {
          onClick: () => {
            setSettingLastSent(false)
            setSettingImportance(false)
            setSettingStrength(false)
          },
        }
      },
      render: (record) => (
        <div className="PeopleTable_Companies">
          <span>{record.companies?.map((t) => t.title).join(", ")}</span>
        </div>
      ),
    },
    {
      title: "Expertises",
      className: "expertises hoverable",
      key: "expertises",
      width: 150,
      onCell: () => {
        return {
          onClick: () => {
            setSettingLastSent(false)
            setSettingImportance(false)
            setSettingStrength(false)
          },
        }
      },
      render: (record) => (
        <div className="PeopleTable_Expertises">
          <span>{record.expertises?.map((t) => t.title).join(", ")}</span>
        </div>
      ),
    },
    {
      title: "Email Addresses",
      className: "email_addresses hoverable",
      key: "email_addresses",
      width: 275,
      onCell: () => {
        return {
          onClick: () => {
            setSettingLastSent(false)
            setSettingImportance(false)
            setSettingStrength(false)
          },
        }
      },
      render: (record) => (
        <div className="PeopleTable_EmailAddresses">
          <span>{record.email_addresses?.map((t) => t.value).join(", ")}</span>
        </div>
      ),
    },
    {
      title: "Phone Numbers",
      className: "phone_numbers hoverable",
      key: "phone_numbers",
      width: 150,
      onCell: () => {
        return {
          onClick: () => {
            setSettingLastSent(false)
            setSettingImportance(false)
            setSettingStrength(false)
          },
        }
      },
      render: (record) => (
        <div className="PeopleTable_PhoneNumbers">
          <span>
            {record.phone_numbers?.map((t) => formatPhoneNumber(t.value)).join(", ")}
          </span>
        </div>
      ),
    },
    {
      title: "Addresses",
      className: "addresses hoverable",
      key: "addresses",
      width: 250,
      onCell: () => {
        return {
          onClick: () => {
            setSettingLastSent(false)
            setSettingImportance(false)
            setSettingStrength(false)
          },
        }
      },
      render: (record) => (
        <div className="PeopleTable_Addresses">
          <span>{record.addresses?.map((t) => t.value).join("; ")}</span>
        </div>
      ),
    },
    {
      title: (
        <div className="SpheresColumn">
          <span>Spheres</span>
          <Tooltip
            overlayClassName="SpheresColumn_CustomizeColumnButton_Tooltip"
            placement="topRight"
            title="Click here to choose what information you see here"
          >
            <Dropdown
              overlay={customizeColumnMenu}
              placement="bottomRight"
              trigger={["click"]}
              visible={dropdownOpen}
            >
              <button
                className="SpheresColumn_CustomizeColumnButton"
                onClick={() => setDropdownOpen(true)}
              >
                <Icon component={TableColumnsIcons} />
              </button>
            </Dropdown>
          </Tooltip>
        </div>
      ),
      className: "spheres hoverable",
      key: "sphere",
      RC_TABLE_INTERNAL_COL_DEFINE: { style: { minWidth: "300px" } }, // << this is the magic which set min width for column
      onCell: (record) => {
        return {
          onClick: (e) => {
            if (record.status === "active") {
              setSettingLastSent(false)
              setSettingImportance(false)
              setSettingStrength(false)
              setSettingSpheres(true)
              if (contactDrawerVisible && visibleContactID === record.id) {
                setSettingSpheres(!settingSpheres)
                e.stopPropagation()
              }
            }
          },
        }
      },
      render: (record) => (
        <>
          {record.status === "active" ? (
            <SpheresSelector
              collapsible
              record={record}
              drawerVisible={contactDrawerVisible}
              alreadySelected={record.spheres}
              clearAfterSubmit={true}
              handleSubmit={(existingSpheres, newSpheres) => {
                dispatch(
                  updatePersonSpheres(record.id, {
                    manual: true,
                    spheres: [...existingSpheres, ...newSpheres],
                  })
                )
                if (existingSpheres.length === 0 && newSpheres.length === 0) {
                  dispatch(deleteContact(record.id))
                  dispatch(setContactDrawerVisible(false))
                  dispatch(setVisibleContactID(null, null))
                  selectedSharedContact(null)
                }
              }}
            />
          ) : (
            <>
              <span className="PeopleTable_Archive">Archived.</span>
              {record.status === "ghost" ? (
                <SpheresSelector
                  collapsible
                  record={record}
                  drawerVisible={true}
                  noSpheresPreview={true}
                  alreadySelected={record.spheres}
                  clearAfterSubmit={true}
                  placeholder={"Unarchive?"}
                  handleSubmit={(existingSpheres, newSpheres) => {
                    dispatch(
                      updatePersonSpheres(record.id, {
                        manual: true,
                        spheres: [...existingSpheres, ...newSpheres],
                      })
                    )
                  }}
                />
              ) : (
                <span
                  onClick={() => {
                    if (record.status === "archived") {
                      dispatch(unarchiveContact(record.id))
                    }
                  }}
                  className="PeopleTable_Unarchive"
                >
                  Unarchive?
                </span>
              )}
            </>
          )}
        </>
      ),
    },
  ].filter((c) => enabledColumns?.includes(c.key))

  const totalEntries = useSelector((state) => state.PeopleState.total_entries)

  const handleTableChange = (newPage, filter, sorter) => {
    let sorterColumnKey, order
    if (sorter?.columnKey) {
      if (sorter?.order === "ascend") {
        order = "asc"
        sorterColumnKey = sorter?.columnKey
      } else if (sorter?.order === "descend") {
        order = "desc"
        sorterColumnKey = sorter?.columnKey
      } else {
        order = null
        sorterColumnKey = null
      }
    } else if (sortField) {
      sorterColumnKey = sortField
      order = sortOrder
    }

    setSortField(sorterColumnKey)
    setSortOrder(order)
    if (Number.isInteger(newPage)) {
      setPage(newPage)
    }

    dispatch(
      fetchPeople(
        Number.isInteger(newPage) ? newPage : page,
        20,
        searchValue,
        filters,
        sorterColumnKey,
        order,
        selectedContactsIds
      )
    )
  }

  useEffect(() => {
    if (!dataProvided) {
      setPage("1")
      setSortOrder("desc")
      setSortField("last_sent")
      dispatch(
        fetchPeople(
          page,
          20,
          searchValue,
          filters,
          sortField || "last_sent",
          sortOrder || "desc",
          selectedContactsIds
        )
      )
    }
    // eslint-disable-next-line
  }, [filters, searchValue])

  let emptyState = (
    <EmptyState
      quote="You see persons and things not as they are but as you are."
      quoteFrom="Anthony De Mello"
      instructions={
        <div>
          People you have added to your database will show up here.{" "}
          <Link to="/account/settings">Have you connected your accounts</Link>?
        </div>
      }
    />
  )

  const handleSelectAllMatchingSearch = () => {
    dispatch(fetchPeopleFromAllPages(searchValue, filters))
  }

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      dispatch(
        selectContacts(
          queriedPeople
            .filter((c) => c !== undefined && c.status === "active")
            .map((c) => ({ id: c.id, full_name: c.full_name }))
        )
      )
    } else {
      dispatch(deselectContacts())
    }
  }

  useEffect(() => {
    if (
      queriedPeople
        .filter((c) => c.status === "active")
        .every((person) => selectedContactsIds.includes(person.id))
    ) {
      setSelectedAllOnPage(true)
    } else {
      setSelectedAllOnPage(false)
    }
  }, [selectedContactsIds, queriedPeople])

  useEffect(() => {
    if (!cloningContact) {
      setTimeout(() => setSelectedSharedContact(null), 1000)
    }
  }, [cloningContact])

  return (
    <div className="PeopleTableWrapper">
      <Tooltip
        placement="topLeft"
        overlayClassName="SelectAll"
        mouseLeaveDelay={0.3}
        title={
          <div className="SelectAll_TooltipTitle">
            <span>{selectedContactsIds.length} selected.&nbsp;</span>
            <button onClick={handleSelectAllMatchingSearch}>
              Select all matching this search
            </button>
          </div>
        }
      >
        <Checkbox
          className="SelectAll_Checkbox"
          checked={selectedAllOnPage}
          onChange={handleSelectAll}
        />
      </Tooltip>
      <Table
        sortDirections={["ascend", "descend", "ascend"]}
        rowKey="id"
        tableLayout="fixed"
        className="PeopleTable"
        loading={{
          spinning: fetching || !queriedPeople,
          indicator: (
            <RelatableLoader quote={true} loading={fetching || !queriedPeople} />
          ),
        }}
        rowSelection={{
          hideSelectAll: true,
          selectedRowKeys: selectedContactsIds,
          columnWidth: 64,
          renderCell: (checked, record, index, originNode) => {
            if (hoveringFlag || selectedContactsIds.length) {
              return (
                <div
                  className="PeopleTable_Checkbox"
                  onMouseEnter={() => setHoveringFlag(true)}
                  onMouseLeave={() => setHoveringFlag(false)}
                >
                  {originNode}
                </div>
              )
            } else {
              return (
                <div
                  onMouseEnter={() => setHoveringFlag(true)}
                  onMouseLeave={() => setHoveringFlag(false)}
                >
                  <FlagOutlined
                    onClick={(e) => {
                      if (record.shared_collection) {
                        setSelectedSharedContact(record)
                      } else {
                        dispatch(setVisibleContactID(record.id, record.slug))
                        dispatch(setContactDrawerVisible(true))
                        e.stopPropagation()
                      }
                    }}
                    className={`PeopleTable_Flag ${
                      record.have_active_task ||
                      (record.reminders && record.reminders.length)
                        ? "overdue"
                        : "empty"
                    }`}
                  />
                </div>
              )
            }
          },
          getCheckboxProps: (record) => {
            return {
              disabled: record.status !== "active",
            }
          },
          onSelect: (record, selected) => {
            if (selected) {
              if (record.shared_collection) {
                setSelectedSharedContact(record)
              } else {
                dispatch(
                  selectContact({ id: record.id, full_name: record.full_name })
                )
              }
            } else {
              setSelectedSharedContact(null)
              dispatch(
                deselectContact({ id: record.id, full_name: record.full_name })
              )
            }
          },
        }}
        onRow={(record) => {
          if (record.status === "active") {
            return {
              onClick: () => {
                if (record.shared_collection) {
                  dispatch(setContactDrawerVisible(false))
                  dispatch(setVisibleContactID(null))
                  dispatch(setActiveTab(null))
                  if (selectedSharedContact?.id === +record.id) {
                    setSelectedSharedContact(null)
                  } else {
                    setSelectedSharedContact(record)
                  }
                } else {
                  setSelectedSharedContact(null)
                  if (contactDrawerVisible && +visibleContactID === +record.id) {
                    dispatch(setContactDrawerVisible(false))
                    dispatch(setVisibleContactID(null))
                    dispatch(setActiveTab(null))
                  } else if (
                    contactDrawerVisible &&
                    +visibleContactID !== +record.id
                  ) {
                    dispatch(setContactDrawerMode("view"))
                    dispatch(setActiveTab(null))
                    dispatch(setVisibleContactID(+record.id, record.slug))
                  } else {
                    dispatch(setActiveTab(null))
                    dispatch(setContactDrawerVisible(true))
                    dispatch(setVisibleContactID(record.id, record.slug))
                  }
                }
              },
            }
          }
        }}
        rowClassName={(record) =>
          `PeopleTable_Row ${visibleContactID === record.id && "active"} ${
            record.status !== "active" && "archived"
          }`
        }
        pagination={false}
        scroll={{
          x: "100%",
        }}
        columns={columns}
        dataSource={queriedPeople}
        onChange={handleTableChange}
        locale={{ emptyText: emptyState }}
      />
      {totalEntries > 0 && (
        <CustomPagination
          total={totalEntries > 10000 ? 10000 : totalEntries}
          currentPage={parseInt(page) || 1}
          onPageChange={(page) => {
            handleTableChange(page)
          }}
          perPage={20}
        />
      )}
      <SharedContactDrawer
        sharedCollection={selectedSharedContact?.shared_collection}
        sharingToken={selectedSharedContact?.shared_collection?.token}
        visible={!!selectedSharedContact}
        contactId={selectedSharedContact?.id}
        handleClose={() => {
          setSelectedSharedContact(null)
        }}
        global={false}
        loadSharedContacts={() => {}}
      />
    </div>
  )
}

export default PeopleTable
