import { useParams } from "react-router"
import React, { useEffect, useState } from "react"
import axios from "axios"
import { Table } from "antd"
import Icon from "@ant-design/icons"

import CustomPagination from "../../components/Database/CustomPagination/CustomPagination.component"
import Avatar from "../../components/UI/Avatar/Avatar.component"
import OverflowTip from "../../components/UI/OverflowTip/OverflowTip.ui"
import StrengthIndicator from "../../components/Database/StrengthIndicator/StrengthIndicator.component"
import moment from "moment"
import * as _ from "lodash"
import ImportanceIndicator from "../../components/Database/ImportanceIndicator/ImportanceIndicator.component"
import ArrowUpIcon from "../../components/Icons/ArrowUp.icons"
import ArrowDownIcon from "../../components/Icons/ArrowDown.icons"
import SpheresSelectorContent from "../../components/Database/SpheresSelector/SpheresSelectorContent/SpheresSelectorContent.component"
import SharedContactDrawer from "./SharedContactDrawer.component"
import PlusCircleIcon from "../../components/Icons/PlusCircle.icons"
import { useSelector } from "react-redux"
import RelatableLoader from "../UI/RelatableLoader/RelatableLoader.component"

const SharedTable = ({ token, activeCollection, global }) => {
  const params = useParams()
  const user = useSelector((state) => state.UserState)
  const [contacts, setContacts] = useState(null)
  const [totalEntries, setTotalEntries] = useState(0)
  const [page, setPage] = useState(1)
  const [fetching, setFetching] = useState(false)
  const [contactId, setContactId] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [searchInput, setSearchInput] = useState(null)

  const fetchSharedCollection = async (
    token,
    page,
    sortBy,
    sordOrder,
    query = null
  ) => {
    const { data } = await axios.request({
      url: `/v1/collection_sharings/contacts/${token}`,
      params: {
        page: page,
        sort_by: sortBy,
        sort_order: sordOrder,
        query: query,
      },
    })
    setFetching(false)
    setTotalEntries(data.total_entries)
    setContacts(data.contacts)
  }

  useEffect(() => {
    setFetching(true)
    fetchSharedCollection(params.token, 1, "last_sent", "desc").catch((error) => {
      if (error.response.status === 401 || error.response.status === 404) {
        setFetching(false)
      }
    })
    // eslint-disable-next-line
  }, [token])

  const searchSharedPeople = (q) => {
    setFetching(true)
    fetchSharedCollection(token, 1, "last_sent", "desc", q)
  }

  const [debouncedSearch] = useState(() => _.debounce(searchSharedPeople, 500))

  const renderSorterIcon = (sortColumns, columnKey) => {
    const sortedColumn = sortColumns?.find(({ column }) => column.key === columnKey)

    if (sortedColumn?.order === "ascend") {
      return <Icon className="PeopleTable_SorterIcon" component={ArrowUpIcon} />
    } else if (sortedColumn?.order === "descend") {
      return <Icon className="PeopleTable_SorterIcon" component={ArrowDownIcon} />
    } else {
      return null
    }
  }

  const handleTableChange = (newPage, sorter) => {
    setFetching(true)

    let sorterColumnKey, order
    if (sorter?.columnKey) {
      if (sorter?.order === "ascend") {
        order = "asc"
        sorterColumnKey = sorter?.columnKey
      } else if (sorter?.order === "descend") {
        order = "desc"
        sorterColumnKey = sorter?.columnKey
      } else {
        order = null
        sorterColumnKey = null
      }
    }

    fetchSharedCollection(token, newPage || page, sorterColumnKey, order)
  }

  const columns = [
    {
      title: ({ sortColumns }) => {
        return (
          <div className={"PeopleTable_NameAndAvatar--paddingLeft"}>
            Name
            {renderSorterIcon(sortColumns, "last_name")}
          </div>
        )
      },
      className: "name hoverable",
      key: "last_name",
      ellipsis: true,
      sorter: contacts?.length > 0,
      defaultSortOrder: "last_sent",
      render: (record) => (
        <div className="PeopleTable_NameAndAvatar PeopleTable_NameAndAvatar--paddingLeft">
          <Avatar
            avatar={record?.profile_image_url}
            firstName={record?.full_name?.split(" ")[0]}
            lastName={record?.full_name?.split(" ")[1]}
            email={
              record?.email_addresses?.length > 0
                ? record.email_addresses[0].value
                : ""
            }
            colorNumber={record.id}
          />
          <div className="PeopleTable_NameAndAvatar_Name">
            <span className="PeopleTable_NameAndAvatar_Name_FullName">
              {(record?.full_name?.trim()?.length > 0 && record.full_name) ||
                (record?.email_addresses?.length > 0 &&
                  record.email_addresses[0].value)}
            </span>
            {record.one_liner && (
              <OverflowTip
                placement="right"
                titleClass="PeopleTable_NameAndAvatar_Name_OneLiner"
              >
                {record.one_liner}
              </OverflowTip>
            )}
          </div>
        </div>
      ),
    },
    {
      title: ({ sortColumns }) => {
        return (
          <div>
            Strength
            {renderSorterIcon(sortColumns, "strength")}
          </div>
        )
      },
      className: "strength hoverable",
      key: "strength",
      width: "10%",
      sorter: contacts?.length > 0,
      render: (record) => <StrengthIndicator level={record.strength} />,
    },
    {
      title: ({ sortColumns }) => {
        return (
          <div>
            Last sent
            {renderSorterIcon(sortColumns, "last_sent")}
          </div>
        )
      },
      className: "last_sent hoverable",
      key: "last_sent",
      width: "12%",
      sorter: contacts?.length > 0,
      render: (record) => (
        <div className="PeopleTable_LastSent">
          {record.last_sent || record.last_received ? (
            <span>
              {record.last_sent
                ? moment(record.last_sent).tz(user.time_zone).format("MMM Do, YYYY")
                : moment(record.last_received)
                    .tz(user.time_zone)
                    .format("MMM Do, YYYY")}
            </span>
          ) : (
            <span>
              {_.sortBy(record.interactions, "last_message_timestamp").reverse()[0]
                ? moment
                    .unix(
                      _.sortBy(
                        record.interactions,
                        "last_message_timestamp"
                      ).reverse()[0].last_message_timestamp
                    )
                    .tz(user.time_zone)
                    .format("MMM Do, YYYY")
                : "N/A"}
            </span>
          )}
        </div>
      ),
    },
    {
      title: ({ sortColumns }) => {
        return (
          <div>
            Importance
            {renderSorterIcon(sortColumns, "importance")}
          </div>
        )
      },
      className: "importance hoverable",
      key: "importance",
      width: "15%",
      sorter: contacts?.length > 0,
      render: (record) => <ImportanceIndicator level={record.importance} />,
    },
    {
      title: "Spheres",
      className: "spheres hoverable",
      key: "sphere",
      width: "25%",
      render: (record) => (
        <SpheresSelectorContent
          onClick={() => null}
          currentSpheres={record.spheres}
          noSpheresPreview={false}
        />
      ),
    },
  ]

  return (
    <div className="SharedTableWrapper">
      <div className="SharedTableWrapper_Header">
        <div className="SearchPeople SharedTableWrapper_Header_Search">
          <input
            value={searchInput}
            placeholder="Search..."
            onChange={(e) => {
              setSearchInput(e.target.value)
              if (e.target.value.length > 2 || e.target.value === "") {
                debouncedSearch(e.target.value)
              }
            }}
          />
        </div>

        {activeCollection?.access_level === 2 && (
          <div
            className="SharedTableWrapper_Header_Add"
            onClick={() => {
              setContactId(null)
              setDrawerVisible(true)
            }}
          >
            <Icon component={PlusCircleIcon} />
            Add
          </div>
        )}
      </div>

      <Table
        rowKey="id"
        tableLayout="fixed"
        className="SharedTable"
        loading={fetching}
        loading={{
          spinning: fetching,
          indicator: <RelatableLoader quote={true} loading={fetching} />,
        }}
        onRow={(record) => {
          if (record.status === "active") {
            return {
              onClick: () => {
                if (+contactId === +record.id) {
                  setContactId(null)
                  setDrawerVisible(false)
                } else {
                  setContactId(record.id)
                  setDrawerVisible(true)
                }
              },
            }
          }
        }}
        rowClassName={(record) =>
          `PeopleTable_Row ${contactId === record.id && "active"}`
        }
        pagination={false}
        scroll={{
          x: true,
          y: "calc(100% - 231px)",
        }}
        columns={columns}
        dataSource={contacts}
        onChange={(pagination, filters, sorter) => handleTableChange(null, sorter)}
        locale={{ emptyText: "No data" }}
      />
      {totalEntries > 0 && (
        <CustomPagination
          total={totalEntries > 10000 ? 10000 : totalEntries}
          currentPage={parseInt(page)}
          onPageChange={(page) => {
            setPage(page)
            handleTableChange(page)
          }}
          perPage={20}
        />
      )}
      {activeCollection && (
        <SharedContactDrawer
          sharedCollection={activeCollection}
          sharingToken={params.token}
          visible={drawerVisible}
          contactId={contactId}
          handleClose={() => {
            setContactId(null)
            setDrawerVisible(false)
          }}
          global={global}
          loadSharedContacts={() =>
            fetchSharedCollection(token, page, "last_sent", "desc")
          }
        />
      )}
    </div>
  )
}

export default SharedTable
