import { Checkbox, Col, Row, Typography } from "antd"
import React from "react"
import Icon from "@ant-design/icons"
import { ReactComponent as UserGroupIcon } from "./../../Icons/UserGroup.svg"

const { Text } = Typography

const AvailableSpheres = ({
  spheres,
  filter,
  currentlySelected,
  onSelect,
  onDeselect,
}) => {
  return spheres.length > 0 ? (
    spheres.map((sphere) => (
      <Row
        onClick={(e) => e.stopPropagation()}
        className={"SphereRow"}
        key={sphere.title}
      >
        <Col span={2}>
          <Checkbox
            checked={currentlySelected.find((s) => s.id === sphere.id)}
            onChange={(e) => {
              if (e.target.checked) {
                onSelect(sphere)
              } else {
                onDeselect(sphere)
              }
              e.stopPropagation()
            }}
          />
        </Col>
        <Col offset={1} span={21}>
          <span style={{ fontWeight: "bold", wordBreak: "break-word" }}>
            {sphere.team_shared && (
              <Icon style={{ marginRight: "8px" }} component={UserGroupIcon} />
            )}
            {sphere.title}
          </span>
        </Col>
      </Row>
    ))
  ) : (
    <div style={{ textAlign: "center" }}>
      <div className={"SpheresSelector_NewSphere-container"}>
        <Text className={"SpheresSelector_NewSphere-text"}>
          Create new sphere: {"\n"}
        </Text>
      </div>
      <div className={"SpheresSelector_SpheresContainer_Sphere-container new"}>
        {filter.length > 0 ? (
          <Text className={"SpheresSelector_SpheresContainer_Sphere-text new"}>
            {filter}
          </Text>
        ) : (
          <Text className={"SpheresSelector_SpheresContainer_Sphere-text"}>
            Start Typing a Sphere Name
          </Text>
        )}
      </div>
    </div>
  )
}

export default AvailableSpheres
