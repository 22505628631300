import React, { useEffect, useRef, useState } from "react"
import * as _ from "lodash"
import Icon from "@ant-design/icons"
import ArrowRightIcon from "../../../Icons/ArrowRight.icons"
import { ReactComponent as UserGroupIcon } from "../../../Icons/UserGroup.svg"

const SpheresSelectorContent = ({
  onClick,
  currentSpheres,
  placeholder,
  noSpheresPreview,
  popupVisible = false,
  children = null,
}) => {
  const spheresContainer = useRef(null)

  const [overflowing, setOverflowing] = useState(false)

  useEffect(() => {
    if (spheresContainer && spheresContainer.current) {
      const hasOverflowingChildren = spheresContainer.current.clientHeight >= 50
      setOverflowing(hasOverflowingChildren)
    }
    // eslint-disable-next-line
  }, [spheresContainer.current])

  useEffect(() => {
    if (spheresContainer && spheresContainer.current) {
      const hasOverflowingChildren = spheresContainer.current.clientHeight >= 50
      setOverflowing(hasOverflowingChildren)
    }
  }, [currentSpheres])

  const renderSphereTitle = (title) => {
    return title.length > 19 ? title.slice(0, 16) + "..." : title
  }

  return (
    <div onClick={() => onClick()} className={"SpheresSelector_SpheresContainer"}>
      {currentSpheres.length === 0 || noSpheresPreview ? (
        <div className={"SpheresSelector_SpheresContainer_Placeholder-container"}>
          <span className={"SpheresSelector_SpheresContainer_Placeholder-text"}>
            {placeholder ||
              (children ? (
                children
              ) : (
                <button
                  className={
                    popupVisible
                      ? "AddSpheresButton AddSpheresButton--active"
                      : "AddSpheresButton"
                  }
                >
                  Add to database <Icon component={ArrowRightIcon} />
                </button>
              ))}
          </span>
        </div>
      ) : (
        <>
          {
            <div className="SpheresContainer" ref={spheresContainer}>
              <>
                {spheresContainer.current && !overflowing ? (
                  <>
                    {currentSpheres.map((sphere) => (
                      <div
                        key={sphere.id}
                        className="SpheresSelector_SpheresContainer_Sphere-container"
                      >
                        <span className="SpheresSelector_SpheresContainer_Sphere-text">
                          {sphere?.team_shared && (
                            <Icon
                              style={{ marginRight: "8px" }}
                              component={UserGroupIcon}
                            />
                          )}
                          {renderSphereTitle(sphere.title)}
                        </span>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {_.sortBy(currentSpheres, "title")
                      .slice(0, 3)
                      .map((sphere) => (
                        <div
                          key={sphere.id}
                          className={
                            "SpheresSelector_SpheresContainer_Sphere-container"
                          }
                        >
                          <span
                            className={
                              "SpheresSelector_SpheresContainer_Sphere-text"
                            }
                          >
                            {sphere?.team_shared && (
                              <Icon
                                style={{ marginRight: "8px" }}
                                component={UserGroupIcon}
                              />
                            )}
                            {renderSphereTitle(sphere.title)}
                          </span>
                        </div>
                      ))}
                    {currentSpheres.length === 4 && (
                      <div
                        className={
                          "SpheresSelector_SpheresContainer_Sphere-container"
                        }
                      >
                        <span
                          className={"SpheresSelector_SpheresContainer_Sphere-text"}
                        >
                          {currentSpheres[3]?.team_shared && (
                            <Icon
                              style={{ marginRight: "8px" }}
                              component={UserGroupIcon}
                            />
                          )}
                          {_.sortBy(currentSpheres, "title")[3].title}
                        </span>
                      </div>
                    )}
                    {currentSpheres.length > 4 && (
                      <div
                        className={
                          "SpheresSelector_SpheresContainer_Sphere-container"
                        }
                      >
                        <span
                          style={{ fontWeight: 600 }}
                          className="SpheresSelector_SpheresContainer_Sphere-text"
                        >
                          and {currentSpheres.length - 3} more
                        </span>
                      </div>
                    )}
                  </>
                )}
              </>
            </div>
          }
        </>
      )}
    </div>
  )
}

export default SpheresSelectorContent
