import React, { useEffect, useState } from "react"
import { Button, Col, DatePicker, Modal, notification, Row, Spin, Card } from "antd"
import moment from "moment"
import axios from "axios"
import RelatableLoader from "./../../UI/RelatableLoader/RelatableLoader.component"
import FadedConvos from "./FadedConvos.component"

const AccountsModal = ({ user_id, visible, onCancel }) => {
  const [accounts, setAccounts] = useState([])
  const [fadedContacts, setFadedContacts] = useState([])
  const [loadingContacts, setLoadingContacts] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState(null)

  const fetchAccounts = async () => {
    await axios
      .request({
        url: "/v1/admin/accounts",
        method: "GET",
        params: {
          user_id: user_id,
        },
      })
      .then((r) => {
        setAccounts(r.data)
      })
  }

  const getFadedConvos = async (email) => {
    setLoadingContacts(true)
    setSelectedEmail(email)
    try {
      const r = await axios.request({
        url: "/v1/nylas/get_recent_participants",
        method: "GET",
        params: {
          email: email,
        },
      })
      setFadedContacts(r.data.faded_contacts)
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to fetch faded conversations",
      })
    } finally {
      setLoadingContacts(false)
    }
  }

  const syncAccount = (id, syncFrom) => {
    axios
      .request({
        url: "/v1/admin/accounts/sync",
        method: "POST",
        data: {
          channel_id: id,
          timestamp: syncFrom,
        },
      })
      .then((r) => {
        fetchAccounts()
        notification.success({
          message: "Success",
          description: "Syncing started",
        })
      })
  }

  useEffect(() => {
    fetchAccounts()
  }, [user_id])

  return (
    <Modal
      closable={false}
      width={"90vw"}
      destroyOnClose
      footer={null}
      title={<h3>Manage accounts</h3>}
      visible={visible}
      onCancel={() => {
        setFadedContacts([])
        setSelectedEmail(null)
        onCancel()
      }}
    >
      <Row gutter={24}>
        <Col span={10}>
          <div style={{ maxHeight: "70vh", overflowY: "auto", padding: "0 1rem" }}>
            {accounts.map((account) => (
              <Card
                key={account.id}
                style={{ marginBottom: "1rem" }}
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{account.email}</span>
                    <span
                      style={{
                        fontSize: "0.9em",
                        padding: "2px 8px",
                        borderRadius: "4px",
                        backgroundColor:
                          account.state === "running" ? "#f6ffed" : "#fff1f0",
                        color: account.state === "running" ? "#52c41a" : "#ff4d4f",
                        border: `1px solid ${
                          account.state === "running" ? "#b7eb8f" : "#ffa39e"
                        }`,
                      }}
                    >
                      {account.state}
                    </span>
                  </div>
                }
              >
                <div style={{ marginBottom: "1rem" }}>
                  <span>Nylas version: {account.grant_id ? "V3" : "V2"}</span>
                </div>
                <Row gutter={16} align="middle">
                  <Col span={8}>
                    <DatePicker
                      placeholder="Sync from"
                      format={"MM/DD/YYYY"}
                      style={{ width: "100%" }}
                      value={account.syncFrom ? moment.unix(account.syncFrom) : null}
                      onChange={(e) => {
                        const newAccounts = accounts.map((a) => {
                          if (a.id === account.id) {
                            a.syncFrom = moment(e).unix()
                          }
                          return a
                        })
                        setAccounts(newAccounts)
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Button
                      onClick={() => syncAccount(account.id, account.syncFrom)}
                      type="primary"
                      block
                      disabled={!account.syncFrom || account.state !== "running"}
                    >
                      Start syncing
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button
                      onClick={() => getFadedConvos(account.email)}
                      type={selectedEmail === account.email ? "default" : "primary"}
                      block
                    >
                      {selectedEmail === account.email
                        ? "Refresh contacts"
                        : "Get faded contacts"}
                    </Button>
                  </Col>
                </Row>
              </Card>
            ))}
          </div>
        </Col>
        <Col span={14}>
          <div
            style={{
              height: "70vh",
              overflowY: "auto",
              backgroundColor: "#fafafa",
              borderRadius: "8px",
              border: "1px solid #f0f0f0",
            }}
          >
            {loadingContacts ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RelatableLoader quote={true} loading={true} />
              </div>
            ) : (
              selectedEmail && (
                <FadedConvos contacts={fadedContacts} userEmail={selectedEmail} />
              )
            )}
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default AccountsModal
