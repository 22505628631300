import React, { useEffect, useState } from "react"
import SpheresSelectorPopUp from "./SpheresSelectorPopup/SpheresSelectorPopup.component"
import * as _ from "lodash"
import "./SpheresSelector.styles.scss"
import { Popover } from "antd"
import { useSelector } from "react-redux"
import SpheresSelectorContent from "./SpheresSelectorContent/SpheresSelectorContent.component"

const SpheresSelector = ({
  placeholder,
  collapsible,
  record,
  drawerVisible,
  alreadySelected,
  handleSubmit,
  clearAfterSubmit,
  noSpheresPreview,
  children,
}) => {
  const queriedPeople = useSelector((state) => state.PeopleState.queriedPeople)
  const [popupVisible, setPopupVisible] = useState(false)
  const [currentSpheres, setCurrentSpheres] = useState([])
  const [newSpheres, setNewSpheres] = useState([])

  useEffect(() => {
    if (record) {
      setCurrentSpheres(
        queriedPeople.find((person) => person.id === record.id).spheres
      )
    }
    // eslint-disable-next-line
  }, [queriedPeople])

  useEffect(() => {
    if (alreadySelected && !record) {
      setCurrentSpheres(currentSpheres.concat(alreadySelected))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (newSpheres.length > 0) {
      setCurrentSpheres([...currentSpheres.filter((s) => !!s.id), ...newSpheres])
    }
    // eslint-disable-next-line
  }, [newSpheres])

  const handleCloseSelector = () => {
    setPopupVisible(false)
    if (newSpheres.length > 0 || !_.isEqual(currentSpheres, alreadySelected)) {
      const existingSpheres = currentSpheres.filter((s) => !!s.id)

      handleSubmit(
        existingSpheres.length > 0
          ? existingSpheres.map((sphere) => ({ id: sphere.id }))
          : [],
        newSpheres.length > 0
          ? newSpheres.map((sphere) => ({ title: sphere.title }))
          : []
      )
      setNewSpheres([])
      if (clearAfterSubmit) {
        setCurrentSpheres([])
      }
    }
  }

  return (
    <Popover
      trigger={"click"}
      placement={"bottom"}
      getPopupContainer={(triggerNode) => {
        return triggerNode.parentElement.parentElement.parentElement.parentElement
          .parentElement.parentElement.parentElement.parentElement.parentElement
          .parentElement
      }}
      visible={popupVisible && drawerVisible}
      onVisibleChange={(visible) => {
        if (!visible) {
          handleCloseSelector()
        }
      }}
      content={
        <SpheresSelectorPopUp
          visible={popupVisible}
          currentlySelected={currentSpheres}
          newSpheres={newSpheres}
          handleCreate={(newSphere) => {
            setNewSpheres(newSpheres.concat(newSphere))
          }}
          handleSelect={(sphere) => {
            setCurrentSpheres([sphere, ...currentSpheres])
          }}
          handleDeselect={(sphere) => {
            setCurrentSpheres(currentSpheres.filter((s) => s.id !== sphere.id))
          }}
          handleSubmit={() => {
            const existingSpheres = currentSpheres.filter((s) => !!s.id)
            handleSubmit(
              existingSpheres.length > 0
                ? existingSpheres.map((sphere) => ({ id: sphere.id }))
                : [],
              newSpheres.length > 0
                ? newSpheres.map((sphere) => ({ title: sphere.title }))
                : []
            )
            setPopupVisible(false)
            setNewSpheres([])
            if (clearAfterSubmit) {
              setCurrentSpheres(alreadySelected || [])
            }
          }}
          handleClose={() => {
            handleCloseSelector()
          }}
        />
      }
    >
      <SpheresSelectorContent
        placeholder={placeholder}
        onClick={() => {
          if (!record?.shared_collection) {
            setPopupVisible(true)
          }
        }}
        popupVisible={popupVisible}
        currentSpheres={_.sortBy(currentSpheres, "title")}
        noSpheresPreview={noSpheresPreview}
        children={children}
      />
    </Popover>
  )
}

export default SpheresSelector
