import React from "react"
import moment from "moment"
import "../InteractionModal.styles.scss"
import { useSelector } from "react-redux"

const DOMPurify = require("dompurify")(window)

const ThreadContent = ({ messageDetails }) => {
  const user = useSelector((state) => state.UserState)
  const to =
    messageDetails.to[0]?.attributes?.data?.registry_data || messageDetails.to[0]
  const from =
    messageDetails.from[0]?.attributes?.data?.registry_data || messageDetails.from[0]

  return (
    <>
      <div className={"InteractionModal__Info"}>
        <span>
          From:{" "}
          {messageDetails && messageDetails.from.length > 0
            ? `${from.name || ""} (${from.email})`
            : "---"}
        </span>
      </div>
      <div className={"InteractionModal__Info"}>
        <span>
          To:{" "}
          {messageDetails && messageDetails.to.length > 0
            ? messageDetails.to
                .map((to) => {
                  return `${to.name || to.email}${to.name ? `(${to.email})` : ""}`
                })
                .join(", ")
            : "---"}
        </span>
      </div>
      {messageDetails && messageDetails.cc.length > 0 && (
        <div className={"InteractionModal__Info"}>
          <span>
            CC:{" "}
            {messageDetails && messageDetails.cc.length > 0
              ? messageDetails.cc
                  .map((cc) => {
                    return `${
                      cc.attributes?.data?.registry_data?.name || cc.name
                    } (${cc.attributes?.data?.registry_data?.email || cc.email})`
                  })
                  .join(", ")
              : "---"}
          </span>
        </div>
      )}

      <div className={"InteractionModal__Info"}>
        <span>
          Sent:{" "}
          {messageDetails
            ? moment
                .unix(messageDetails.date)
                .tz(user.time_zone)
                .format("MMMM Do, hh:mm A")
            : "---"}
        </span>
      </div>
      {messageDetails?.body && (
        <div
          className={`InteractionModal__Body`}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              messageDetails.body.split("\n").join("<br/>")
            ),
          }}
        />
      )}
    </>
  )
}

export default ThreadContent
