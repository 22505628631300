import { useSelector } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import { Input, Row } from "antd"
import AvailableSpheres from "../AvailableSpheres.component"
import SubmitSphereButton from "./SubmitSphereButton.component"
import { useSpheresOrder } from "../../../../hooks/useSpheresOrder"

const SpheresSelectorPopUp = ({
  visible,
  currentlySelected,
  newSpheres,
  handleSelect,
  handleDeselect,
  handleCreate,
  handleSubmit,
  handleClose,
}) => {
  const [filter, setFilter] = useState("")
  const [filteredSpheres, setFilteredSpheres] = useState([])
  const [saving, setSaving] = useState(false)
  const spheres = useSelector((state) => state.CollectionsState.spheres)
  const teamSharedSpheres = useSelector(
    (state) => state.CollectionsState.team_shared_spheres
  )
  const spheres_order = useSelector((state) => state.CollectionsState.spheres_order)
  const { orderSpheres } = useSpheresOrder()
  // eslint-disable-next-line
  const invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g
  const regex = new RegExp(`^.*${filter.replace(invalid, "").toLowerCase()}.*$`, "g")

  const sphereInput = useRef(null)

  const updateQuery = (value) => {
    setFilter(value)
  }

  useEffect(() => {
    const orderedSpheres = orderSpheres(
      spheres.concat(newSpheres),
      teamSharedSpheres
    )
    setFilteredSpheres(
      orderedSpheres.filter((s) => s.title.toLowerCase().match(regex))
    )
    // eslint-disable-next-line
  }, [newSpheres, spheres, teamSharedSpheres, filter, spheres_order])

  useEffect(() => {
    setTimeout(() => {
      if (sphereInput.current) {
        sphereInput.current.focus()
      }
    }, 500)
  }, [visible])

  return (
    <>
      <div className={"SpheresSelector_SpheresPopup"}>
        <AvailableSpheres
          filter={filter}
          spheres={filteredSpheres}
          currentlySelected={currentlySelected}
          onSelect={handleSelect}
          onDeselect={handleDeselect}
          onSearch={updateQuery}
        />
      </div>
      <Row>
        <Input
          ref={sphereInput}
          className={"SpheresInput"}
          value={filter}
          placeholder={"Start typing to filter spheres"}
          onChange={(e) => updateQuery(e.target.value)}
          onClick={(e) => e.stopPropagation()}
        />
      </Row>
      {filteredSpheres.length > 0 ? (
        currentlySelected.length > 0 || saving ? (
          <SubmitSphereButton
            onClick={(e) => {
              setSaving(true)
              handleSubmit()
              setFilter("")
              e.stopPropagation()
            }}
            className="SpheresSelector_SpheresPopup_SubmitButton active"
          >
            Save
          </SubmitSphereButton>
        ) : (
          <div
            onClick={handleClose}
            className="SpheresSelector_SpheresPopup_SubmitButton disabled"
          >
            Close
          </div>
        )
      ) : (
        <div
          onClick={(e) => {
            handleCreate({ id: null, title: filter })
            setFilter("")
            e.stopPropagation()
          }}
          className={"SpheresSelector_SpheresPopup_SubmitButton"}
        >
          Create
        </div>
      )}
    </>
  )
}

export default SpheresSelectorPopUp
